import { Injectable, inject } from '@angular/core';
import { MapInitializerService } from '@my-tomorrows/components';
import { Observable, filter, from, map, of, switchMap } from 'rxjs';
import { PlacesAutoCompleteItem } from '../interfaces/places.interface';

@Injectable({ providedIn: 'root' })
export class PlacesService {
  private readonly mapInitializerService = inject(MapInitializerService);
  private readonly isApiReady$ = this.mapInitializerService.isMapReady$;

  searchPlaceByQuery(query: string): Observable<PlacesAutoCompleteItem[]> {
    if (!query) {
      return of([]);
    }

    const getPlacesFromApi = () => {
      const sessionToken = new google.maps.places.AutocompleteSessionToken();
      const request = {
        input: query,
        sessionToken,
      };
      const service = new google.maps.places.AutocompleteService();
      return from(service.getPlacePredictions(request)).pipe(
        map((places: google.maps.places.AutocompleteResponse) => {
          return places.predictions.map((place) => ({
            title: place.description,
            placeId: place.place_id,
          }));
        }),
      );
    };

    return this.isApiReady$.pipe(
      filter((v) => !!v),
      switchMap(() => getPlacesFromApi()),
    );
  }
}
