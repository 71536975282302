import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  OnInit,
  Output,
  WritableSignal,
  inject,
  input,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  AutocompleteComponent,
  ButtonType,
  ChipInputComponent,
  InputComponent,
  MenuComponent,
  Option,
  TabContentDirective,
  TabsComponent,
  autocompleteValidator,
} from '@my-tomorrows/components';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, finalize, map, startWith, take } from 'rxjs';
import { RADIUS_DISTANCE_VALUES } from '../../constants/search-filter.constants';
import { COUNTRY_EUROPE } from '../../constants/search-results.constants';
import { PlacesAutoCompleteItem } from '../../interfaces/places.interface';
import { SearchFilterParamKeys, SearchFilterParams } from '../../interfaces/search-filter-params.interface';
import { DistanceUnit, LocationTabs, RadiusForm } from '../../interfaces/search-filter.interface';
import { PlacesService } from '../../servises/places.service';
import { SearchNewService } from '../../servises/search-new.service';

@Component({
  selector: 'myt-location',
  standalone: true,
  imports: [
    TabsComponent,
    TabContentDirective,
    MenuComponent,
    AutocompleteComponent,
    TranslateModule,
    ReactiveFormsModule,
    InputComponent,
    ChipInputComponent,
    AsyncPipe,
  ],
  templateUrl: './location.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationComponent implements OnInit {
  private readonly newSearchService = inject(SearchNewService);
  private readonly placesService = inject(PlacesService);
  private readonly translateService = inject(TranslateService);
  private readonly destroyRef = inject(DestroyRef);

  readonly $countryForm = input.required<FormControl<string[] | null>>();
  readonly $radiusForm = input.required<RadiusForm>();
  readonly $initialTab = input<LocationTabs>(LocationTabs.Country);
  @Output() filterValueChanges = new EventEmitter<SearchFilterParams>();

  readonly $distanceAmounts = signal<Option[]>([]);
  readonly $distanceUnits = signal([
    { label: DistanceUnit.Km, selected: true },
    { label: DistanceUnit.Mile, selected: false },
  ]);
  readonly $placesOptions: WritableSignal<Option[]> = signal([]);
  readonly $loading: WritableSignal<boolean> = signal(false);
  readonly $countriesOptions: WritableSignal<Option[]> = signal([]);
  readonly $radiusUnitMenuItems = signal<Array<{ label: string; selected: boolean }>>([]);
  readonly $tabTitles = signal<Array<string>>([
    this.translateService.instant('SEARCH.LOCATION_TABS.COUNTRY'),
    this.translateService.instant('SEARCH.LOCATION_TABS.LOCATION'),
  ]);
  selectedCountries$?: Observable<Option[]>;

  readonly buttonType = ButtonType;
  readonly countryQueryControl = new FormControl('', [autocompleteValidator]);

  ngOnInit(): void {
    this.setDistanceOptions();
    this.selectedCountries$ = this.$countryForm()?.valueChanges.pipe(
      startWith(this.$countryForm().value),
      map((countries: string[] | null) => countries?.map((country) => ({ value: country, title: country })) || []),
    );

    this.$radiusUnitMenuItems.set([
      {
        label: `${DistanceUnit.Km}  ${this.translateService.instant('SEARCH.DISTANCE.KM')}`,
        selected: this.$radiusForm().value.unit === DistanceUnit.Km,
      },
      {
        label: `${DistanceUnit.Mile} ${this.translateService.instant('SEARCH.DISTANCE.MI')}`,
        selected: this.$radiusForm().value.unit === DistanceUnit.Mile,
      },
    ]);
  }

  setCountries(value: string): void {
    this.$loading.set(true);
    this.newSearchService
      .getCountries({ value })
      .pipe(
        map((countries) => {
          const isQueryIncEurope = value && COUNTRY_EUROPE.value.toLowerCase().includes(value.toLowerCase());
          return [...(!value || isQueryIncEurope ? [COUNTRY_EUROPE] : []), ...(countries || [])];
        }),
        finalize(() => this.$loading.set(false)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((options: Option[]) => {
        this.$countriesOptions.set(options);
      });
  }

  onRemoveCountryViaChip(index: number) {
    const selectedCOuntry = this.$countryForm().value?.[index] as string;
    this.onCountrySelect({ value: selectedCOuntry, title: selectedCOuntry });
  }

  onUnitSelect(value: string): void {
    const _value = value.startsWith(DistanceUnit.Km) ? DistanceUnit.Km : DistanceUnit.Mile;
    this.$radiusForm().controls.unit?.patchValue(_value as DistanceUnit);
    this.$radiusUnitMenuItems.update((items) => items.map((i) => ({ ...i, selected: value === i.label })));
    this.setDistanceOptions();
    if (this.$radiusForm().value.place?.value) {
      this.filterValueChanges.emit({ [SearchFilterParamKeys.RadiusUnit]: _value });
    }
  }

  onDistanceSelect(option: Option) {
    if (this.$radiusForm().value.place?.value) {
      this.filterValueChanges.emit({ [SearchFilterParamKeys.RadiusDistance]: option.value as string });
    }
  }

  onCountrySelect(selectedOption: Option): void {
    const currentSelectedCountries = this.$countryForm().value || [];
    const isSelectedOptionALreadySelected = currentSelectedCountries.some((countryValue: string) => countryValue === selectedOption.value);
    const newCountries: string[] = isSelectedOptionALreadySelected
      ? currentSelectedCountries.filter((country) => country !== selectedOption.value)
      : [...currentSelectedCountries, selectedOption.value as string];

    this.filterValueChanges.emit({
      [SearchFilterParamKeys.Country]: newCountries,
      [SearchFilterParamKeys.RadiusPlaceId]: undefined,
      [SearchFilterParamKeys.RadiusPlaceName]: undefined,
      [SearchFilterParamKeys.RadiusUnit]: undefined,
      [SearchFilterParamKeys.RadiusDistance]: undefined,
    });
    this.$radiusForm().controls.place.reset();
    this.$radiusForm().controls.distance.reset(this.$distanceAmounts()[3]);
    this.$countryForm().patchValue(newCountries as string[]);
    this.countryQueryControl.reset('');
  }

  onPlaceSelect(option: Option) {
    this.filterValueChanges.emit({
      [SearchFilterParamKeys.RadiusPlaceId]: option.value as string,
      [SearchFilterParamKeys.RadiusPlaceName]: option.title,
      [SearchFilterParamKeys.RadiusUnit]: this.$radiusForm().value.unit as DistanceUnit,
      [SearchFilterParamKeys.RadiusDistance]: this.$radiusForm().value.distance?.value as string,
      [SearchFilterParamKeys.Country]: undefined,
    });
    this.countryQueryControl.reset();
    this.$countryForm().reset();
  }

  searchPlaceByQuery(value: string) {
    if (!value) {
      this.$placesOptions.set([]);
    }
    this.$loading.set(true);

    this.placesService
      .searchPlaceByQuery(value)
      .pipe(
        take(1),
        finalize(() => this.$loading.set(false)),
      )
      .subscribe((places: PlacesAutoCompleteItem[]) => {
        const _places = places.map((i) => ({ title: i.title, value: i.placeId }));
        this.$placesOptions.set(_places);
      });
  }

  private patchDistanceFormValue(): void {
    const initialValue = this.$radiusForm().value.distance?.value;
    const newValue = initialValue ? this.$distanceAmounts().find((i) => +i.value === +initialValue) : this.$distanceAmounts()[3];

    this.$radiusForm().controls.distance.patchValue(newValue || null);
  }

  private setDistanceOptions(): void {
    const unitForm = this.$radiusForm().controls.unit;

    unitForm.valueChanges.pipe(startWith(unitForm.value), takeUntilDestroyed(this.destroyRef)).subscribe((unit) => {
      this.$distanceAmounts.set(
        RADIUS_DISTANCE_VALUES.map((i) => {
          const anyDistanceLabel = this.translateService.instant('SEARCH.ANY_RADIUS_DISTANCE');
          return {
            value: i,
            title: i === 0 ? anyDistanceLabel : `< ${i} ${unit}`,
          };
        }),
      );

      this.patchDistanceFormValue();
    });
  }
}
