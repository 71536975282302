import { Injectable } from '@angular/core';
import { Config, Driver, driver, DriveStep } from 'driver.js';

@Injectable({
  providedIn: 'root',
})
export class DriverService {
  private defaultDriverOptions: Config = {
    popoverClass: 'myt-driver-popover',
    overlayOpacity: 0.25,
    stageRadius: 5,
  };

  highlight(options: DriveStep, driverOptions: Config = {}): Driver {
    const driverObj = driver({ ...this.defaultDriverOptions, ...driverOptions });

    driverObj.highlight({
      element: options.element,
      popover: {
        title: options.popover?.title,
        description: options.popover?.description,
        align: options.popover?.align,
        side: options.popover?.side,
        ...options.popover,
      },
      onHighlightStarted: options.onHighlightStarted,
      onHighlighted: options.onHighlighted,
      onDeselected: options.onDeselected,
    });

    return driverObj;
  }
}
