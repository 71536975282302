import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Use this to ensure the autocomplete has a returned object as the value of the form as opposed to the search string.
//  */
export const autocompleteValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }

  if (typeof control.value === 'string') {
    return { autocomplete: { value: control.value } };
  }

  return null;
};
