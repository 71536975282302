import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, OnInit, output, signal, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipInput, MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { distinctUntilChanged } from 'rxjs';
import { ChipComponent } from '../../chip/chip.component';
import { IconSize } from '../../icon/icon-size.enum';
import { IconsComponent } from '../../icon/icon.component';
import { BaseInputDirective } from '../base-input/base-input.directive';
import { MytInputError } from '../input/input-error.pipe';
import { LabelComponent } from '../label/label.component';

@Component({
  selector: 'myt-chip-input',
  standalone: true,
  imports: [
    ChipComponent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgClass,
    MatAutocompleteModule,
    LabelComponent,
    MatChipsModule,
    MatIconModule,
    MytInputError,
    FormsModule,
    IconsComponent,
  ],
  templateUrl: './chip-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipInputComponent extends BaseInputDirective implements OnInit {
  readonly inputElem = viewChild<MatChipInput>('inputElem');

  readonly chipItems = input<string[]>([]);
  /**
   * Use values from @angular/cdk/keycodes like ENTER, COMMA
   */
  readonly chipInputSeparatorKeyCodes = input<number[]>([]);
  readonly addOnBlur = input<boolean>(false);
  readonly removeItem = output<number>();
  readonly addItem = output<string>();

  readonly hideInput = signal<boolean>(true);

  readonly iconSize = IconSize.ExtraSmall;

  override ngOnInit(): void {
    super.ngOnInit();

    // Fixes the issue with cleaning the input field after selection on autocomplete via keyboard.
    this.control()
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
      .subscribe((v) => {
        if (!v && this.inputElem()) {
          this.inputElem()?.clear();
        }
      });
  }

  onChipRemove(chipItemIndex: number): void {
    this.removeItem.emit(chipItemIndex);
    this.hideInput.set(true);
  }

  onFieldFocus(event: Event): void {
    const clickedElement = (event.target as HTMLElement)?.lastChild;
    // Dont activate input if user removes the chip
    if (clickedElement?.nodeName !== 'MYT-ICON') {
      this.hideInput.set(false);
    }
  }

  addChip(event: MatChipInputEvent): void {
    const value = event.value?.trim();

    if (value) {
      this.addItem.emit(value);
    }

    event.chipInput!.clear();
  }

  onBlur(event: Event): void {
    if (event.target) {
      (event.target as HTMLInputElement).value = '';
      this.control().reset('');
    }

    if (this.chipItems().length) {
      this.hideInput.set(true);
    }
  }
}
