import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, input, Output, signal } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  AutocompleteComponent,
  autocompleteValidator,
  ButtonNativeType,
  ButtonsComponent,
  ButtonType,
  CheckboxComponent,
  DriverService,
  IconsComponent,
  IconSize,
  InputComponent,
  InputType,
  Option,
  requireEither,
  SpinnerComponent,
  TextareaComponent,
} from '@my-tomorrows/components';
import { AnalyticsService, PostHogEvents, SessionStorageService } from '@my-tomorrows/shared-data';
import { changeTouchState } from '@my-tomorrows/shared-utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { catchError, of, take } from 'rxjs';
import { MEDICAL_SUMMARY_KEY } from '../../constants/search-results.constants';
import { SearchFilterParamKeys, SearchFilterParams } from '../../interfaces/search-filter-params.interface';
import { AliasResponseItem, CheckboxFormGroup, DistanceUnit, RadiusForm } from '../../interfaces/search-filter.interface';
import { SearchNewService } from '../../servises/search-new.service';
import { LocationComponent } from '../location/location.component';

@Component({
  selector: 'myt-search-new',
  standalone: true,
  imports: [
    AutocompleteComponent,
    CheckboxComponent,
    InputComponent,
    LocationComponent,
    ReactiveFormsModule,
    SpinnerComponent,
    TranslateModule,
    ButtonsComponent,
    TextareaComponent,
    IconsComponent,
    NgClass,
  ],
  templateUrl: './search-new.component.html',
  styleUrl: './search-new.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchNewComponent {
  readonly searchNewService = inject(SearchNewService);
  readonly analyticsService = inject(AnalyticsService);
  readonly sessionStorage = inject(SessionStorageService);
  readonly driverService = inject(DriverService);
  readonly translateService = inject(TranslateService);
  readonly enableTrialMatch = input<boolean>(false);

  @Output() searchParamsChanges = new EventEmitter<SearchFilterParams>();

  readonly form = new FormGroup({
    condition: new FormControl<string | Option>(''),
    conditionAliases: new FormArray<CheckboxFormGroup>([]),
    country: new FormControl<string[]>([]),
    profile: new FormControl<string>(''),
    otherTerms: new FormControl(''),
    radius: new FormGroup({
      distance: new FormControl<Option>({ value: '', title: '' }),
      place: new FormControl<Option>({ value: '', title: '' }, [autocompleteValidator]),
      unit: new FormControl<DistanceUnit>(DistanceUnit.Km),
    }) as RadiusForm,
  });

  readonly buttonType = ButtonType;
  readonly inputType = InputType;
  readonly iconSize = IconSize;
  readonly buttonNativeType = ButtonNativeType;

  readonly $isAliasesLoading = signal(false);
  private readonly requireEitherValidator = requireEither('condition', 'otherTerms');
  private readonly profileRequiredValidator = Validators.required;

  startNewSearch(findMatchesWithPrescreening = false): void {
    this.form.setValidators(this.requireEitherValidator);
    if (findMatchesWithPrescreening) {
      this.form.controls.profile.setValidators(this.profileRequiredValidator);
    } else {
      this.form.controls.profile.removeValidators(this.profileRequiredValidator);
    }
    changeTouchState(Object.values(this.form.controls), true);

    if (this.form.valid) {
      this.closeWithNewSearchParams(findMatchesWithPrescreening);
      this.sessionStorage.setItem(MEDICAL_SUMMARY_KEY, this.form.value.profile);
    }

    const event = findMatchesWithPrescreening ? PostHogEvents.TrialPrescreeningConducted : PostHogEvents.TrialSearchConducted;
    this.analyticsService.posthogCapture(event, { has_fail_validation: !this.form.valid });
  }

  private closeWithNewSearchParams(findMatchesWithPrescreening = false): void {
    const condition = this.form.controls.condition.value as Option;
    const country = this.form.controls.country;
    const otherTerms = this.form.controls.otherTerms.value;
    const radiusForm = this.form.controls.radius.value;
    const profile = this.form.value.profile;
    const alsoSearchForParam = this.getAliasesFormValue();

    const resetFilters: Partial<SearchFilterParams> = {
      [SearchFilterParamKeys.Country]: undefined,
      [SearchFilterParamKeys.Age]: undefined,
      [SearchFilterParamKeys.Gender]: undefined,
      [SearchFilterParamKeys.OtherTerms]: undefined,
      [SearchFilterParamKeys.Phase]: undefined,
      [SearchFilterParamKeys.ProtocolType]: undefined,
      [SearchFilterParamKeys.OverallStatus]: undefined,
      [SearchFilterParamKeys.Profile]: undefined,
    };

    if (condition?.value || otherTerms) {
      this.searchParamsChanges.emit({
        ...resetFilters,
        [SearchFilterParamKeys.Condition]: condition.value as string,
        [SearchFilterParamKeys.Country]: country?.value as string[],
        [SearchFilterParamKeys.OtherTerms]: otherTerms as string,
        [SearchFilterParamKeys.AlsoSearchFor]: alsoSearchForParam as string[],
        [SearchFilterParamKeys.Profile]: findMatchesWithPrescreening ? (profile as string) : undefined,
        ...(radiusForm.place?.value
          ? {
              [SearchFilterParamKeys.RadiusPlaceName]: radiusForm.place?.title as string,
              [SearchFilterParamKeys.RadiusPlaceId]: radiusForm.place?.value as string,
              [SearchFilterParamKeys.RadiusUnit]: radiusForm.unit as DistanceUnit,
              [SearchFilterParamKeys.RadiusDistance]: radiusForm.distance?.value || 0,
            }
          : {}),
      });
    }
  }

  onSelectCondition(event: Option): void {
    this.$isAliasesLoading.set(true);
    this.searchNewService
      .getAliases({ value: event.title as string })
      .pipe(
        take(1),
        catchError(() => of([])),
      )
      .subscribe({
        next: (aliases: AliasResponseItem[]) => this.addConditionAliasesForm(aliases),
        complete: () => this.$isAliasesLoading.set(false),
      });
  }

  onConditionFieldBlur() {
    if (!this.form.controls.condition.value) {
      this.form.controls.conditionAliases.clear();
    }
  }

  removeValidator() {
    this.form.removeValidators(this.requireEitherValidator);
    changeTouchState([this.form.controls.otherTerms, this.form.controls.condition], false);
  }

  showMedicalSummaryExample() {
    const driverObj = this.driverService.highlight(
      {
        element: '#initiate-trial-form-medical-summary-container',
        popover: {
          title: this.translateService.instant('SEARCH.MEDICAL_SUMMARY_EXAMPLE_TEXT.0'),
          description: this.translateService.instant('SEARCH.MEDICAL_SUMMARY_EXAMPLE_TEXT.1'),
          align: 'end',
          side: 'top',
          showButtons: ['close', 'next'],
        },
      },
      {
        nextBtnText: this.translateService.instant('SEARCH.MEDICAL_SUMMARY_EXAMPLE_INNER_BTN'),
        onCloseClick: () => driverObj.destroy(),
        onNextClick: () => {
          this.form.controls.profile.setValue(this.translateService.instant('SEARCH.MEDICAL_SUMMARY_EXAMPLE_TEXT.1'));
          driverObj.destroy();
        },
      },
    );
  }

  private getAliasesFormValue(): string[] {
    const aliasesFormValue = this.form.value.conditionAliases || [];
    const isAllCheckboxesUnchecked = aliasesFormValue.length && aliasesFormValue.every((val) => val.checked === false);

    if (isAllCheckboxesUnchecked) {
      return ['null'];
    }

    return aliasesFormValue.filter((synonym) => synonym.checked).map((synonym) => synonym.label) as string[];
  }

  private addConditionAliasesForm(synonyms: Array<AliasResponseItem>): void {
    const synonymFormArray = this.form.controls['conditionAliases'];

    // Remove previous form array elements
    synonymFormArray.clear();
    synonyms.forEach((synonym) => {
      synonymFormArray.push(
        new FormGroup({
          label: new FormControl(synonym.value),
          checked: new FormControl(synonym.isCheckedByDefault),
        }),
      );
    });
  }
}
