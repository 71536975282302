import { FormControl, FormGroup } from '@angular/forms';
import { Option } from '@my-tomorrows/components';

export interface AliasResponseItem {
  isCheckedByDefault: boolean;
  value: string;
}

/**
 * @todo Open for tech debt: checkbox ui-component can be redesigned in order to have better interaction with parent.
 * Therefore we can update this model or even remove.
 */
export type CheckboxFormGroup = FormGroup<{
  label: FormControl<string | null>;
  checked: FormControl<boolean | null>;
}>;

export type RadiusForm = FormGroup<{
  unit: FormControl<DistanceUnit | null>;
  distance: FormControl<Option | null>;
  place: FormControl<Option | null>;
}>;

export enum DistanceUnit {
  Km = 'km',
  Mile = 'mi',
}
export enum LocationTabs {
  Country = 0,
  Places = 1,
}
