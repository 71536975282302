import { AliasData, AlsoSearchFor } from '../interfaces/condition-aliases-response.interface';
import { AliasResponseItem } from '../interfaces/search-filter.interface';

export function mapAliasesResponseToUi(response: AliasData): AliasResponseItem[] {
  const synonyms = response.data.aliases[0].aliasForConcept[0].conceptOf[0].descriptorFor.map((alias: AlsoSearchFor) => ({
    isCheckedByDefault: true,
    value: alias.knownAsTerm[0].alias,
  }));

  const alsoSearchItems = response.data.aliases[0].aliasForConcept[0].alsoSearchFor?.map((alias: AlsoSearchFor) => ({
    isCheckedByDefault: false,
    value: alias.knownAsTerm[0].alias,
  }));

  return [...synonyms, ...(alsoSearchItems || [])];
}
