import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { Option } from '@my-tomorrows/components';
import { Observable, finalize, map, take } from 'rxjs';
import { COUNTRIES_PINNED_OPTIONS } from '../constants/search-results.constants';
import { ConditionsResponse } from '../interfaces/conditions-response.interface';
import { CountriesResponse } from '../interfaces/countries-response.interface';
import { AliasResponseItem } from '../interfaces/search-filter.interface';
import { mapAliasesResponseToUi } from '../utils/alias-mapper.util';
import { SearchDataGraphQlService } from './search-data-graphql.service';

@Injectable({
  providedIn: 'root',
})
export class SearchNewService {
  private readonly searchDataGraphQlService = inject(SearchDataGraphQlService);

  readonly $conditionOptions: WritableSignal<Option[]> = signal([]);
  readonly $countriesOptions: WritableSignal<Option[]> = signal([]);
  readonly $loading: WritableSignal<boolean> = signal(false);

  getConditions(params: { value: string }): Observable<Option[]> {
    return this.searchDataGraphQlService.getConditions(params).pipe(
      map((response: ConditionsResponse) => {
        return response.data.autoComplete.map((item: { alias: string }) => ({
          value: item.alias,
          title: item.alias,
        }));
      }),
    );
  }

  getCountries(params: { value: string }): Observable<Option[]> {
    return this.searchDataGraphQlService.getCountries(params).pipe(
      map((response: CountriesResponse) => {
        return response.data.countryAutoComplete.map((item: string) => ({
          value: item,
          title: item,
          pinned: COUNTRIES_PINNED_OPTIONS.includes(item),
        }));
      }),
    );
  }

  getAliases(params: { value: string }): Observable<Array<AliasResponseItem>> {
    return this.searchDataGraphQlService.getAliases(params).pipe(
      map(mapAliasesResponseToUi),
      map((aliases) => aliases.filter((alias) => alias.value !== params.value)),
    );
  }

  onConditionValueChanges(value: string): void {
    this.$loading.set(true);

    this.getConditions({ value })
      .pipe(
        take(1),
        finalize(() => this.$loading.set(false)),
      )
      .subscribe((options: Option[]) => this.$conditionOptions.set(options));
  }

  onCountriesValueChanges(value: string): void {
    this.$loading.set(true);

    this.getCountries({ value })
      .pipe(
        take(1),
        finalize(() => this.$loading.set(false)),
      )
      .subscribe((options: Option[]) => this.$countriesOptions.set(options));
  }
}
