<div class="myt-chip-input">
  @if (label()) {
    <myt-label [hasError]="control().invalid && control().touched" [control]="control()" [label]="label()" />
  }

  <mat-form-field class="w-full" appearance="outline" (focusin)="onFieldFocus($event)">
    <mat-chip-grid #chipList>
      @for (chipItem of chipItems(); track $index) {
        <mat-chip-row (removed)="onChipRemove($index)" class="myt-chip myt-chip--neutral">
          <span class="truncate">{{ chipItem }}</span>

          <button class="myt-chip__remove-button" matChipRemove aria-label="remove chip">
            <myt-icon name="close" [size]="iconSize" />
          </button>
        </mat-chip-row>
      }
      <input
        #inputElem="matChipInput"
        class="py-auto"
        [class.myt-chip-input__input--hidden]="chipItems().length && hideInput()"
        [ngClass]="inputCssClasses()"
        type="text"
        [formControl]="control()"
        [placeholder]="placeholder()"
        [matAutocomplete]="autocomplete()!"
        [matAutocompleteDisabled]="!autocomplete()"
        [maxlength]="maxLength()"
        (focus)="onFocus()"
        (blur)="onBlur($event)"
        [matChipInputFor]="chipList"
        [matChipInputAddOnBlur]="addOnBlur()"
        [matChipInputSeparatorKeyCodes]="chipInputSeparatorKeyCodes()"
        (matChipInputTokenEnd)="addChip($event)"
      />
    </mat-chip-grid>

    @if (hint()) {
      <mat-hint
        ><span class="myt-input__hint">{{ hint() }}</span></mat-hint
      >
    }

    <mat-error>
      <span> {{ control().errors ? (control().errors! | mytInputError: customValidationMessages()) : '' }} </span>
    </mat-error>
  </mat-form-field>
</div>
