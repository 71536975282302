<myt-tabs [tabTitles]="$tabTitles()" [defaultTabIndex]="$initialTab()">
  <!-- Country tab -->
  <ng-template mytTabContent>
    <myt-chip-input
      [control]="countryQueryControl"
      [chipItems]="$countryForm().value || []"
      [hint]="'SEARCH.LOCATION_HINT' | translate"
      [placeholder]="'SEARCH.COUNTRY_PLACEHOLDER' | translate"
      [autocomplete]="countryAutocomplete.autocomplete()!"
      [customValidationMessages]="{ autocomplete: 'SEARCH.AUTOCOMPLETE_ERROR_COUNTRY' }"
      class="mb-2"
      (removeItem)="onRemoveCountryViaChip($event)"
      data-role="search-filter-country-input"
    >
      <myt-autocomplete
        #countryAutocomplete
        [control]="countryQueryControl"
        [options]="$countriesOptions()"
        [loading]="$loading()"
        (valueChanges)="setCountries($event)"
        (selectOption)="onCountrySelect($event)"
        [multiSelect]="true"
        [selectedOptions]="(selectedCountries$ | async) || []"
      />
    </myt-chip-input>
  </ng-template>

  <!-- Radius tab -->
  <ng-template mytTabContent>
    <myt-input
      [control]="$radiusForm().controls.place"
      [hint]="'SEARCH.LOCATION_HINT' | translate"
      [placeholder]="'SEARCH.LOCATION_PLACEHOLDER' | translate"
      [autocomplete]="placesAutocomplete.autocomplete()!"
      class="mb-4 block"
      data-role="search-filter-place-input"
    >
      <myt-autocomplete
        #placesAutocomplete
        [control]="$radiusForm().controls['place']"
        [options]="$placesOptions()"
        [loading]="$loading()"
        (valueChanges)="searchPlaceByQuery($event)"
        (selectOption)="onPlaceSelect($event)"
        [hideDropdown]="!($placesOptions().length || $loading())"
      />
    </myt-input>

    <div class="mt-1 flex flex-row justify-between font-semibold">
      <label data-role="search-filter-distance-label">{{ 'SEARCH.RADIUS_LABEL' | translate }}</label>
      <myt-menu
        [textLabel]="$radiusForm().controls.unit.value || ''"
        [items]="$radiusUnitMenuItems()"
        [labelCssClasses]="'font-medium'"
        [menuPanelCssClasses]="'mt-[5px]'"
        (itemSelect)="onUnitSelect($event)"
      />
    </div>

    <myt-input
      [control]="$radiusForm().controls.distance"
      [hint]="'SEARCH.RADIUS_HINT' | translate"
      [placeholder]="'SEARCH.RADIUS_PLACEHOLDER' | translate"
      [autocomplete]="distanceUnitAutocomplete.autocomplete()!"
      data-role="search-filter-distance-input"
    >
      <myt-autocomplete
        [control]="$radiusForm().controls.distance"
        #distanceUnitAutocomplete
        [options]="$distanceAmounts()"
        (selectOption)="onDistanceSelect($event)"
      />
    </myt-input>
  </ng-template>
</myt-tabs>
